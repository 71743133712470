import Metadata from "../Metadata";

// interface detail {
//     nama_unit: string,
//     u: string
// };

export default class viewLaprekapdietKelas {
    metadata: Metadata = {
        err_code: 0,
        list_count: 0,
        message: ''
    };
    list: Array<any> = []
}
